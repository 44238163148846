<template>
    <div class="eatXfbPiceLog">
        <div class="main-flex">
            
            <edit-btns class="btn-box" 
                :bnts="['query','goBack','output']"
                @queryClick="loadData()"
                @outputClick="exportExcel()"
                @goBackClick="back()"/>
            <div class="title-box">
                时价菜操作日志
            </div>
        </div>
        <div class="body-box">
            <div class="flex-box">
                <div class="filter-box">
                    <div class="from-box">
                        <div class="lable-txt">查询日期:</div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                                :clearable="false"
                                :editable="false"
                                v-model="businessHours"
                                type="date"
                                placeholder=""
                                :default-value="new Date()">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">检索:</div>
                        <key-board-input class="from-input search"
                            type="text" v-model="selectContent" placeholder="按编号、名称模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                            @keydown.enter="onEnter()"
                        ></key-board-input>
                    </div>
                </div>
                <div class="content-box clearfix">
                    <div class="table-box grey-table" >
                        <el-table class="el-table--scrollable-y"  :data="tableListPage"  ref="elTable" style="width: 100%;height:100%;"
                            border 
                            @sort-change="sortChange" 
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" >
                            <el-table-column sortable="custom" prop="EAT_XFCODE" label="菜品编码" min-width="100" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="EAT_XFNAME" label="菜品名称" min-width="100" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="EAT_XFSIZE" label="单位" min-width="80" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="EAT_KINDNAME" label="上级分类" min-width="80" ></el-table-column>
                            <el-table-column sortable="custom" prop="OLDEAT_XFPRICE" label="原售价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column sortable="custom" prop="EAT_XFPRICE" label="设定售价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column sortable="custom" prop="OLDEAT_PARPRICE" label="原会员价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column sortable="custom" prop="EAT_PARPRICE" label="设定会员价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column sortable="custom" prop="LASTUPDATED_USER" label="最后发布人" min-width="100" align="left"></el-table-column> 
                            <el-table-column sortable="custom" prop="LASTUPDATED_TIME" label="最后发布时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column>                         
                        </el-table>
                    </div>
                    <table-page-btn class="fy" 
                        :default-index="currentPage" 
                        @current-change="(index)=>currentPage=index"
                        :data="tableFilter"
                        @page-change="(list)=>tableListPage=list" 
                        :pageSizes="[20,30,50,100]"
                        :pageSize="pageSize"
                        @size-change="(size)=>pageSize=size"></table-page-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"eatXfbPiceLog",
    data(){
        let pageSize= this.$cacheData.get("eatXfbPiceLog_pageSize")||20;
        return {
            /**查询日期 */
            businessHours:new Date(),
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pageSize:pageSize,//每页的数据条数
            selectContent:"",
            sortable:null
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatXfbPiceLog_pageSize",newVal);
        },
    },
    computed:{
        //条件筛选
        tableFilter(){
            let list=this.tableList||[];
            if(list && list.length>0){
                if(this.selectContent!=''){
                    let seach=this.selectContent.toUpperCase();
                    list=list.filter(it=>
                        (it.EAT_XFCODE||'').toUpperCase().indexOf(seach)>=0 
                        || (it.EAT_XFNAME||'').toUpperCase().indexOf(seach)>=0
                        || (it.PINYIN||'').toUpperCase().indexOf(seach)>=0
                    );
                }
            }
            //排序
            if(this.sortable){
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            }
            return list;
        }
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadData();
    },
    methods:{
        back(){
            this.$router.push("eatXfbPiceUpdate");
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.elTable,
                titleName:"时价菜操作日志",
                list:this.tableFilter
            })
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /**账单数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        /**查询 */
        loadData(){
            let userInfo = this.$auth.getUserInfo();
            let param={
                User_ID:userInfo?.User_ID,
                Operator_Name:userInfo?.Login_Name,
                RPT_DATE:(new Date(this.businessHours)).Format('yyyy-MM-dd'),//查询日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.GetUpdateFoodPriceLogs",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody;
                }else{
                    this.tableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
    }
}
</script>

<style lang="scss">
    @import './eatXfbPiceLog.scss'
</style>